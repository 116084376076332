import React, { Component, Fragment } from 'react';
import {Helmet} from "react-helmet";
import GridLoader from "react-spinners/GridLoader";
import Calendar from './Calendar';



class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 600)
  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <meta charset="utf-8"/>
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta name="viewport" content="initial-scale=1, maximum-scale=1"/>
          <title>コテージ こだま【ペンションふんわりこん】</title>
          <meta name="keywords" content="ふんわりこん, コテージ, こだま, ホテル, 宿泊, 富士サファリパーク, ぐりんぱ, 富士山こどもの国, 子連れ, 御殿場プレミアムアウトレット, 富士急ハイランド, 富士山, ペンション, 宿"/>
          <meta name="description" content="ペンションふんわりこん コテージこだまの公式ホームページです。コテージこだま」は、2022年に新築したふんわりこんの離れです。プライベートな時間をゆっくり過ごしていただきたく、外観、室内空間、調度品にこだわりました。"/>

          <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Zen+Kurenaido" rel="stylesheet"/>
          {/* <link href="https://fonts.googleapis.com/css2?family=Mochiy+Pop+P+One" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css2?family=Rampart+One&display=swap" rel="stylesheet"/>*/}
          <link href="https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200&display=swap" rel="stylesheet"/>
          <link href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=M+PLUS+1" rel="stylesheet" />

          <link rel="stylesheet" href="../css/cottage/bootstrap.min.css"/>
          <link rel="stylesheet" href="../css/cottage/style.css"/>
          <link rel="stylesheet" href="../css/cottage/responsive.css"/>

          {/* <link rel="icon" href="../images/cottage/fevicon.png" type="image/gif" />
          <link rel="stylesheet" href="../css/cottage/jquery.mCustomScrollbar.min.css"/>
          <link rel="stylesheet" href="https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css"/>
          <link rel="stylesheet" href="../css/cottage/owl.carousel.min.css"/>
          <link rel="stylesheet" href="../css/cottage/owl.theme.default.min.css"/>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/fancybox/2.1.5/jquery.fancybox.min.css" media="screen"/> */}
        </Helmet>

        <div className='loading' style={{display: !this.state.isLoading ? 'none' : ''}}>
          <GridLoader
            color='#ffffff'
            loading={this.state.isLoading}
            cssOverride={false}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>

        <div style={{display: this.state.isLoading ? 'none' : ''}}>

          <div id="top" className="header_top">
            <div className="container">
              <div className="row">
                 {/* <div className="col-sm-5">
                  <div className="contact_main">
                    <div className="contact_left">
                      <div className="call_text"><a href="#"><img src="../images/cottage/call-icon.png"/><span className="call_text_left">+01 1234567890</span></a></div>
                    </div>
                    <div className="contact_right">
                      <div className="call_text">Make an appointment</div>
                    </div>
                  </div>
                </div> */}
                <div className="col-sm-2">
                  <div className="logo"><a href="#top"><img alt="logo" src="../images/cottage/kodama_logo.png"/></a></div>
                </div>
                 {/* <div className="col-sm-5">
                  <div className="contact_main">
                    <div className="contact_left">
                      <div className="call_text">xxxxxxxxxxxxxx</div>
                    </div>
                    <div className="contact_right">
                      <div className="call_text"><a href="#"><img src="../images/cottage/map-icon.png"/><span className="call_text_left">+01 9876543210</span></a></div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="funwarikon-logo1" >
              <img alt="logo" className="navbar-toggler" src="../images/cottage/funwarikon_logo.png" style={{width: 110, border: "none"}}/>
            </div>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="funwarikon-logo2">
                <img alt="funwarikon-logo" style={{width: 135, border: "none", paddingRight: 14}} src="../images/cottage/funwarikon_logo.png" />
              </div>
              <div className="navbar-nav">
                <a className="nav-item nav-link" href="#top">TOP</a>
                <a className="nav-item nav-link" href="#services">サービス</a>
                <a className="nav-item nav-link" href="#fee">料金/ご予約</a>
                <a className="nav-item nav-link" href="#calendar">空室情報</a>
                <a className="nav-item nav-link" href="#faq">よくあるお問合せ(FAQ)</a>
              </div>
            </div>
          </nav>


          <hr className="nav-line" style={{height: 6, width: "100%", margin: "0 0 16px 0", borderTop: "none", backgroundColor: "#104e3d"}}/>

          <div className="banner_section">
            <section className="slide-wrapper">
              <div className="container">
                <div id="myCarousel" className="carousel slide" data-ride="carousel">

                      <ol className="carousel-indicators">
                          <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                          <li data-target="#myCarousel" data-slide-to="1"></li>
                          <li data-target="#myCarousel" data-slide-to="2"></li>
                          <li data-target="#myCarousel" data-slide-to="3"></li>
                      </ol>

                      <div className="carousel-inner">

                          <div className="carousel-item active">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img alt="exterior" className="carousel-image" src="../images/cottage/exterior.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">コテージこだま<br/>オープン!!</h1>
                                    <p className="best_text">2022年12月 New Open!!</p>
                                    <p className="lorem_text">
                                    「コテージこだま」は、2022年に新築したふんわりこんの離れです。<br/>
                                    プライベートな時間をゆっくり過ごしていただきたく、外観、室内空間、調度品にこだわりました。

                                    </p>
                                    {/* <div className="appointment_bt"><a href="#">Make an appointment</a></div> */}
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div className="carousel-item">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img alt="living" className="carousel-image" src="../images/cottage/living.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">使い方いろいろ</h1>
                                    <p className="best_text">旅行も、ワーケーションも</p>
                                    <p className="lorem_text">
                                      家族旅行、グループ、小規模企業研修、ワーケーションなど多目的にご利用いただけます。<br/>
                                      Wi-Fi完備、会議用モニター、書斎、キッチンなど、小規模な企業研修や長期滞在でも対応可能な設備をご用意しています。
                                    </p>
                                    {/* <div className="appointment_bt"><a href="#">Make an appointment</a></div> */}
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div className="carousel-item">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img alt="dinner" className="carousel-image" src="../images/cottage/dinner.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">プライベートな<br/>空間で過ごす</h1>
                                    <p className="best_text">離れのコテージでゆったりとした時間を</p>
                                    <p className="lorem_text">
                                      本館とは独立した離れになっており、プライベートな空間でご滞在いただくことが可能です。<br/>
                                      お食事は、簡単に温めて召し上がれる部屋食のご用意の他、持ち込んだ食材をキッチンでお料理してお楽しみいただくこともできます。
                                    </p>
                                    {/* <div className="appointment_bt"><a href="#">Make an appointment</a></div> */}
                                  </div>
                                </div>
                              </div>
                          </div>

                          <div className="carousel-item">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img alt="coffee" className="carousel-image" src="../images/cottage/coffee.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">特別な体験も</h1>
                                    <p className="best_text">焙煎したてのコーヒーを楽しむ</p>
                                    <p className="lorem_text">
                                      コテージ内にコーヒー焙煎機の設置があり、焙煎体験、焙煎したてのコーヒーを楽しむことができます。（有料・要予約）<br/>
                                      ここでしかできない特別な体験をお楽しみください。
                                    </p>
                                    {/* <div className="appointment_bt"><a href="#">Make an appointment</a></div> */}
                                  </div>
                                </div>
                              </div>
                          </div>

                           {/* <div className="carousel-item">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img className="carousel-image" src="../images/cottage/coffee.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">Achive Your Style</h1>
                                    <p className="best_text">The best place for your hairstyle</p>
                                    <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                                    <div className="appointment_bt"><a href="#">Make an appointment</a></div>
                                  </div>
                                </div>
                              </div>
                          </div> */}
                          {/* <div className="carousel-item">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img src="../images/cottage/coffee1.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">Achive Your Style</h1>
                                    <p className="best_text">The best place for your hairstyle</p>
                                    <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                                    <div className="appointment_bt"><a href="#">Make an appointment</a></div>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <div className="carousel-item">
                              <div className="container">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="image_1"><img src="../images/cottage/coffee1.png"/></div>
                                  </div>
                                  <div className="col-md-6">
                                    <h1 className="achive_text">Achive Your Style</h1>
                                    <p className="best_text">The best place for your hairstyle</p>
                                    <p className="lorem_text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
                                    <div className="appointment_bt"><a href="#">Make an appointment</a></div>
                                  </div>
                                </div>
                              </div>
                          </div> */}
                      </div>
                  </div>
              </div>
            </section>
          </div>


          <div id="services" className="service_section layout_padding">
            <div className="container">
              <p className="services_caption">Our Services</p>
              <h1 className="services_text underline-dark">サービスご紹介</h1>
              <p className="dolor_text">本館のお客様と離れたプライベートな空間でおくつろぎください</p>
              <div className="service_section_2">

                <div className="row">

                  <div className="col-sm-4">
                    <div className="image_3"><img alt="living" className="services-image" src="../images/cottage/living.png"/></div>
                    <h2 className="classic_text">リビング</h2>
                    <p className="consectetur_text">
                      ご家族やお仲間とくつろぐリビングとして。ワーケーションのワークスペースとして。<br/>
                      いろいろな使い方をしていただけるゆったりとしたリビングスペースです。
                    </p>
                    {/* <div className="read_bt_main">
                      <div className="read_bt"><a href="#">Read More</a></div>
                    </div> */}
                  </div>

                  <div className="col-sm-4">
                    <div className="image_3"><img alt="room" className="services-image" src="../images/cottage/room.png"/></div>
                    <h2 className="classic_text">お部屋</h2>
                    <p className="consectetur_text">
                      コテージこだまの中に、3部屋のツインルームのご用意がございます。<br/>
                      小学生以上のお客様6名様と、添い寝の未就学児のお客様3名様までご宿泊いただけます。
                    </p>
                    {/* <div className="read_bt_main">
                      <div className="read_bt"><a href="#">Read More</a></div>
                    </div> */}
                  </div>

                  <div className="col-sm-4">
                    <div className="image_3"><img alt="dinner" className="services-image" src="../images/cottage/dinner.png"/></div>
                    <h2 className="classic_text">お食事</h2>
                    <p className="consectetur_text">
                      お食事はコテージこだま専用の特別なメニューをお部屋にご用意いたします。お客様のお好きなときに温めてお召し上がりいただきます。<br/>
                      お好きなものを持ち込んでいただき、備え付けのキッチンでお料理いただくことも可能です。
                    </p>
                    {/* <div className="read_bt_main">
                      <div className="read_bt"><a href="#">Read More</a></div>
                    </div> */}
                  </div>

                  <div className="col-sm-4">
                    <div className="image_3"><img alt="den" className="services-image" src="../images/cottage/den.png"/></div>
                    <h2 className="classic_text">書斎</h2>
                    <p className="consectetur_text">
                      寝室の１つに書斎を完備し、テレワークにもご利用いただけます。<br/>
                      ワーケーションをご検討のお客様もぜひご利用ください。
                    </p>
                    {/* <div className="read_bt_main">
                      <div className="read_bt"><a href="#">Read More</a></div>
                    </div> */}
                  </div>

                  <div className="col-sm-4">
                    <div className="image_3"><img alt="kitchen" className="services-image" src="../images/cottage/kitchen.png"/></div>
                    <h2 className="classic_text">キッチン</h2>
                    <p className="consectetur_text">
                      コテージこだまにはキッチンも完備しております。<br/>
                      お好きな料理をお楽しみいただいたり、長期滞在の場合も便利にご利用いただけます。
                    </p>
                    {/* <div className="read_bt_main">
                      <div className="read_bt"><a href="#">Read More</a></div>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>



          <div id="fee" className="about_section layout_padding">
            <div className="container">
              <div className="row table">
                <div className="col-md-6">
                  <p className="unique_text">Fee / Reservetion</p>
                  <h1 className="years_text">料金/ご予約</h1>
                  <p className="ipsum_text">
                  コテージこだま は、本館のお客様と分離したプライベート空間を確保した一棟貸しの貸切コテージです。<br/><br/>
                  料金はご利用人数に応じた1棟のご利用料金となります。<br/><br/>
                  小学生以上2〜6名様（＋添い寝の幼児３名様）までの人数でご利用いただけます。
                  </p>
                  <div className="read_more_bt"><a href="https://www.hpdsp.net/funwarikon/hw/hwp3100/hww3101.do?yadNo=329166">
                    <img alt="web" src="../images/cottage/web-icon.png" style={{width: 20, marginRight:12}}/>
                    WEB予約
                  </a></div>
                  <div className="read_more_bt"><a href="tel://+81559981433">
                    <img alt="phone" src="../images/cottage/phone-icon.png" style={{width: 22, marginRight:12}}/>
                    電話予約　(055-998-1433)
                  </a></div>
                  <div className="read_more_bt"><a href="https://lin.ee/SZYBL0q">
                    <img alt="line" src="../images/cottage/line-icon.png" style={{width: 30, marginRight:12}}/>
                    LINE予約
                  </a></div>
                </div>
                <div className="col-md-6" style={{paddingRight:0}}>
                  <div className="price ">

                    <table>
                      <thead>
                        <tr>
                          <th>ご利用人数</th>
                          <th>素泊まり</th>
                          <th>2食付き</th>
                          <th>夕食付き</th>
                          <th>朝食付き</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr>
                          <td>2名様</td>
                          <td>21,000円〜</td>
                          <td rowSpan='5' style={{verticalAlign: 'middle'}}>+5,000円</td>
                          <td rowSpan='5' style={{verticalAlign: 'middle'}}>+3,500円</td>
                          <td rowSpan='5' style={{verticalAlign: 'middle'}}>+1,500円</td>
                        </tr>
                        <tr>
                          <td>3名様</td>
                          <td>14,000円〜</td>
                        </tr>
                        <tr>
                          <td>4名様</td>
                          <td>12,000円〜</td>
                        </tr>
                        <tr>
                          <td>5名様</td>
                          <td>11,000円〜</td>
                        </tr>
                        <tr>
                          <td>6名様</td>
                          <td>10,000円〜</td>
                        </tr>
                        <tr>
                          <td>未就学児</td>
                          <td colSpan='4'>3,000円 (３名様まで)</td>
                        </tr>
                      </tbody>
                    </table>
                    <p style={{color: '#ffffff'}}>
                      ※記載は、1名様分の料金です。<br/>
                      ※小学生は大人料金より2000円割引いたします。<br/>
                      ※ご利用人数は小学生以上を1名様としてカウントいたします。(未就学児はご利用人数にカウントされません。)<br/>
                      ※未就学児の方の料金は添い寝(食事なし)の料金となります。<br/>
                      ※ご利用料金は時期により変動する場合がございます。<br/>
                      
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>

         {/* <div className="calendar" style={{ height: 680, width: "70%", margin: "20px auto"}}>

         </div>
   */}

          <div id="calendar" className="contact_section">
            <div className="container calendar">
              <p className="services_caption">Vacancy information</p>
              <h1 className="contact_taital underline-dark">コテージこだま空室情報</h1>
              <Calendar/>
            </div>
          </div>

          {/* <div className="discount_section layout_padding">
             <div className="container">
              <div className="discount_bg">
                <div className="row">
                  <div className="col-sm-8">
                    <h1 className="get_tetx">GET FLAT 25% DISCOUNT</h1>
                    <h1 className="every_tetx">ON EVERY SUNDAY OF DECEMBER</h1>
                    <h1 className="promo_tetx">USE PROMO CODE: BARBERDIS</h1>
                  </div>
                  <div className="col-sm-4">
                    <div className="image_6"><img src="../images/cottage/img-6.png"/></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          {/* <div className="client_section layout_padding">
            <h1 className="client_taital">What is Say Our Customer</h1>
            <div className="container">
              <div id="main_slider" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="alex_bg">
                          <h3 className="alex_text">Alex jorze</h3>
                          <p className="reader_text">t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="alex_bg">
                          <h3 className="alex_text">Stip mark</h3>
                          <p className="reader_text">t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="alex_bg">
                          <h3 className="alex_text">Alex jorze</h3>
                          <p className="reader_text">t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="alex_bg">
                          <h3 className="alex_text">Stip mark</h3>
                          <p className="reader_text">t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="alex_bg">
                          <h3 className="alex_text">Alex jorze</h3>
                          <p className="reader_text">t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="alex_bg">
                          <h3 className="alex_text">Stip mark</h3>
                          <p className="reader_text">t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <a className="carousel-control-prev" href="#main_slider" role="button" data-slide="prev">
                  <i className="fa fa-long-arrow-left"></i>
                </a>
                <a className="carousel-control-next" href="#main_slider" role="button" data-slide="next">
                  <i className="fa fa-long-arrow-right"></i>
                </a>
              </div>
            </div>
          </div> */}

          <div id="faq" className="faq_section layout_padding">
            <div className="container">
              <p className="faq_caption">FAQ</p>
              <h1 className="faq_taital underline-light">よくあるお問合せ</h1>


              <div className="accordion" id="accordionExample">

                <div className="card">
                  <div className="card-header" id="heading1">
                    <h5 className="mb-0">
                      <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapse1" aria-expanded="false" aria-controls="collapse1">
                        定員は何名ですか？
                      </button>
                    </h5>
                  </div>
                  <div id="collapse1" className="collapse" aria-labelledby="heading1">
                    <div className="card-body">
                      コテージこだまの定員は6名様です。小学生から定員にカウントさせていただきます。未就学児につきましては最大3名様まで添寝でご利用いただけます。
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header" id="heading2">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                        チェックインは何時から何時までですか？
                      </button>
                    </h5>
                  </div>
                  <div id="collapse2" className="collapse" aria-labelledby="heading2">
                    <div className="card-body">
                      チェックインは15：00から22：00までにお願いいたします。
                    </div>
                  </div>
                </div>

              <div className="card">
                <div className="card-header" id="heading3">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                      チェックアウト時間は何時ですか？
                    </button>
                  </h5>
                </div>
                <div id="collapse3" className="collapse" aria-labelledby="heading3">
                  <div className="card-body">
                    チェックアウト時間は10:00でございます。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading4">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                      食事付きプランの場合、夕食はどのようになりますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse4" className="collapse" aria-labelledby="heading4">
                  <div className="card-body">
                    夕食はお部屋食になります。<br/>
                    コテージ内の冷蔵庫にご用意しておきますので、お好きなお時間にお召し上がりください。<br/>
                    温めとお取り分けはお客様にお願いしております。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading5">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                      食事付きプランの場合、朝食はどのようになりますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse5" className="collapse" aria-labelledby="heading5">
                  <div className="card-body">
                    朝食は朝7：30または8：30に朝食ボックスをお渡しいたします。<br/>
                    フロントにてお受け取りください。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading6">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                      食事のアレルギーへの対応は可能ですか？
                    </button>
                  </h5>
                </div>
                <div id="collapse6" className="collapse" aria-labelledby="heading6">
                  <div className="card-body">
                    大変申し訳ございませんが、アレルギー除去食への対応はできません。<br/>
                    万一、食材の一部がアレルギーの対象となってしまった場合などを考慮し、お客様の安全を第一とさせていただきたい為、ご了承いただけますようお願いいたします。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading7">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse7" aria-expanded="false" aria-controls="collapse7">
                      未就学児のお食事についてはどうなりますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse7" className="collapse" aria-labelledby="heading7">
                  <div className="card-body">
                    未就学児のプランは添い寝(食事なし)のプランのみになります。<br/>
                    大人の分をお取り分けいただくか、お好きなものをお持ちください。<br/>
                    また、大人と同じお食事をご希望の場合は小学生以上としてご予約ください。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading7-1">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse7-1" aria-expanded="false" aria-controls="collapse7-1">
                      バーラウンジはありますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse7-1" className="collapse" aria-labelledby="heading7-1">
                  <div className="card-body">
                    19：30〜22：00（ラストオーダー21：30）は本館ラウンジでバータイムとしてご利用いただけます。<br/>
                    たくさんのお飲み物メニューとおつまみのご用意がございます。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading7-2">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse7-2" aria-expanded="false" aria-controls="collapse7-2">
                      バーベキューはできますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse7-2" className="collapse" aria-labelledby="heading7-2">
                  <div className="card-body">
                    キャンプ場やグランピング施設とは異なりバーベキューや焚き火はできません。<br/>
                    コテージ内でホットプレートを利用することは可能です。（ホットプレートは備品としてご用意がございます。）
                  </div>
                </div>
              </div>


              <div className="card">
                <div className="card-header" id="heading8">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse8" aria-expanded="false" aria-controls="collapse8">
                      コテージにはどんなアメニティがありますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse8" className="collapse" aria-labelledby="heading8">
                  <div className="card-body">
                    バスタオル、フェイスタオル、歯ブラシ（お子様用もあります）、シャンプー、コンディショナー、ボディーソープ、乳液、化粧水がございます。<br/>
                    その他、お部屋着はございませんので、お客様ご自身でご用意ください。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading10">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse10" aria-expanded="false" aria-controls="collapse10">
                      どんな支払方法が使えますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse10" className="collapse" aria-labelledby="heading10">
                  <div className="card-body">
                    現金、クレジットカード、電子マネー(QuickPay、iD、各種交通系電子マネー)、PayPayがご利用いただけます。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading11">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse11" aria-expanded="false" aria-controls="collapse11">
                      キャンセル料について教えてください。
                    </button>
                  </h5>
                </div>
                <div id="collapse11" className="collapse" aria-labelledby="heading11">
                  <div className="card-body">
                    キャンセル料は以下の通りとなっております。<br/><br/>

                    9日より前のキャンセル : 無料<br/>
                    3日前〜8日前のキャンセル : 宿泊料の30%<br/>
                    前日/前々日のキャンセル　: 宿泊料の60%<br/>
                    当日のキャンセル/連絡なしのキャンセル : 宿泊料の100%<br/>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading12">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                      ペットを連れていくことは可能ですか？
                    </button>
                  </h5>
                </div>
                <div id="collapse12" className="collapse" aria-labelledby="heading12">
                  <div className="card-body">
                    恐れ入りますが、盲導犬・聴導犬・介助犬を除き、館内へのペットの持ち込みはお断りさせていただいております。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading13">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse13" aria-expanded="false" aria-controls="collapse13">
                      コテージ内に友人を招くことはできますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse13" className="collapse" aria-labelledby="heading13">
                  <div className="card-body">
                    コテージ内にご宿泊者様以外のお客様は入れません。ご了承ください。
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header" id="heading14">
                  <h5 className="mb-0">
                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse14" aria-expanded="false" aria-controls="collapse14">
                      花火はできますか？
                    </button>
                  </h5>
                </div>
                <div id="collapse14" className="collapse" aria-labelledby="heading14">
                  <div className="card-body">
                    敷地内での花火はご遠慮いただいております。
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

          <div className="join_section">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="member_text">Cottage KODAMA Since 2022</h2>
                </div>
                <div className="col-md-6">
                  <div className="join_bt"><a href="#top">TOPへ戻る</a></div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer_section layout_padding">
            <div className="container">
              <div className="social_icon">

                <a href="#top"><img alt="logo" style={{minWidth:120, maxWidth: 160}} src="../images/cottage/kodama_logo.png"/></a>
                <a href="https://funwarikon.com"><img alt="funwarikon-logo" style={{maxWidth:160 }} src="../images/cottage/funwarikon_logo2.png"/></a>

              </div>
              <p className="lorem_ipsum_text">皆様のお越しをスッタフ一同、心よりお待ちしております</p>
            </div>
          </div>

          <div className="copyright_section">
            <div className="container">
              <p className="copyright_text">Copyright 2022 All Right Reserved By.<a href="https://funwarikon.com"> ペンション ふんわりこん</a></p>
            </div>
          </div>

        </div>

      </Fragment>
    );
  }
}

export default Main ;
