import React, { Component, Fragment } from 'react';
import {Calendar as BigCalendar, momentLocalizer} from 'react-big-calendar';
import './css/react-big-calendar.css';
import moment from 'moment';
import 'moment/locale/ja';
import $ from 'jquery';
import JapaneseHolidays from 'japanese-holidays';
import { API } from 'aws-amplify';

let navigate = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
}

class CustomToolbar extends React.Component {
    render() {
        let { label } = this.props
        return(
            <div className="rbc-toolbar">
                <span className="rbc-btn-group">
                    <button type="button" onClick={this.navigate.bind(null, navigate.PREVIOUS)}>前月</button>
                </span>
                <span className="rbc-toolbar-label">{label}</span>
                <span className="rbc-btn-group">
                    <button type="button" onClick={this.navigate.bind(null, navigate.NEXT)}>翌月</button>
                </span>
            </div>
        )
    }
    navigate = action => {
        this.props.onNavigate(action)
    }
}

let formats = {
   dateFormat: 'D',
   monthHeaderFormat : 'YYYY年 M月',
}

const initialState = {
  event: [],
  homePageReservations: [],
  firstDay: "",
  calendarError: "none",

};

const localizer = momentLocalizer(moment) // or globalizeLocalizer

class Calendar extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount(){

    this.setState({
      homePageReservations: [],
      firstDay: "",
      calendarError: "none",
    }, async () => {

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      let firstDay = moment().startOf('month').format("YYYY-MM-DD")

      let path = '/outside/allocations'
      if(window.location.pathname === '/cottage'){
        path = '/outside/allocations-cottage'
      }

      const myInit = {
        queryStringParameters: {
          date: firstDay
        },
      };

      try{
        const response = await API.get(apiName, path, myInit)
        console.log(response);
        this.setState({
          homePageReservations: response.reservations,
          firstDay: firstDay
        })

      }catch(error){
        console.log(error);
      }

    })
  }

  setJapaneseHolidays = () => {
    let firstDay = moment(this.state.firstDay)
    let year = firstDay.year();
    let monthDays = firstDay.daysInMonth();

    let japaneseHolidays = JapaneseHolidays.getHolidaysOf(year);

    let japaneseHolidaysThisMonth = japaneseHolidays.filter((holiday) => {
      if(moment().year(year).month(holiday.month - 1).date(holiday.date).format("YYYY-MM") === firstDay.format("YYYY-MM")){
        return true
      }
      return false
    })

    if(japaneseHolidaysThisMonth.length > 0){
      for (let i = 1 ; i < monthDays + 1; i++) {

        japaneseHolidaysThisMonth.map((holiday) => {
          if(firstDay.date(i).format("YYYY-MM-DD") === moment().year(year).month(holiday.month -1).date(holiday.date).format("YYYY-MM-DD")){
            setTimeout(() => {
              $(".rbc-day-bg:not('.rbc-off-range-bg')").eq(i - 1).addClass("holiday-bg")
              $(".rbc-date-cell:not('.rbc-off-range')").eq(i - 1).addClass("holiday")
              $(".holiday-name").eq(i - 1).html(holiday.name)
            }, 0)
          }
          return false
        })
      }
    }
  }

  onRangeChange = (event) => {
    this.setState({
      homePageReservations: [],
      firstDay: "",
      calendarError: "none",
      isRangeButtonActive: false
    }, async () => {

      setTimeout(() => {
        this.setState({
          isRangeButtonActive: true
        })
      }, 500)

      let day = new Date(event.start);
      day.setDate(day.getDate() + 7)
      let firstDay = moment(new Date(day.getFullYear(), day.getMonth(), 1)).format("YYYY-MM-DD");

      let apiName = 'MyAPIGatewayAPI'; // replace this with your api name.
      
      let path = '/outside/allocations'
      if(window.location.pathname === '/cottage'){
        path = '/outside/allocations-cottage'
      }

      const myInit = {
        queryStringParameters: {
          date: firstDay
        },
      };

      try{
        const response = await API.get(apiName, path, myInit)
        console.log(response);
        this.setState({
          homePageReservations: response.reservations,
          firstDay: firstDay
        })
      }catch(error){
        console.log(error);
      }
    })
  }

  render() {
    const {
      event,
      homePageReservations,
      firstDay,
      calendarError
    } = this.state;

    return (
      <Fragment>
        <BigCalendar
          localizer={localizer}
          events={event}
          startAccessor="start"
          endAccessor="end"
          views={['month']}
          formats={formats}
          components={{
            toolbar: CustomToolbar
          }}
          onSelectEvent={(event) => this.onSelectEvent(event)}
          onRangeChange={(event) => this.onRangeChange(event)}
          toolbar={this.state.isRangeButtonActive}
          eventPropGetter={
            (event) => {
              let newStyle = {
                backgroundColor: "lightgrey"
              };

              if (event.isReserved){
                newStyle.backgroundColor = "#04d0d0"
              }

              if (event.doubleBooking){
                newStyle.backgroundColor = "#ff0000d1"
              }

              return {
                className: "",
                style: newStyle
              };
            }
          }
          dayPropGetter={
            (date) => {
              setTimeout(() => {
                $($(".rbc-date-cell:not('.rbc-off-range')")).eq(moment(date).format("D") - 1).html((index, element) => {
                  let str = element
                  let str1 = str.replace('<a href="#">' + moment(date).format("D") + '</a>', '<a href="#">' + moment(date).format("D") + '<br/><div class="holiday-name">　</div></a>')
                  return str1
                })

                let state = "ー"
                let stateClass = ""
                let stateText = ""

                if(moment(date).isSameOrAfter(moment().startOf("day"))){
                  state = "○"
                  stateClass = "reservation-state-ok"
                  stateText ="空室有り"
                }

                homePageReservations.map((reservation) => {
                  if(moment(date).format("YYYY-MM-DD") === moment(reservation.date).format("YYYY-MM-DD")){
                    if(reservation.state === "few"){
                      state = "△"
                      stateClass = "reservation-state-few"
                      stateText ="残り僅か"
                    }
                    if(reservation.state === "full"){
                      state = "×"
                      stateClass = "reservation-state-full"
                      stateText ="満室"
                    }
                    if(reservation.state === "beforeOpen"){
                      state = "ー"
                      stateClass = ""
                      stateText =""
                    }
                  }
                  return false
                })

                if(moment(date).format("YYYY-MM") === moment(firstDay).format("YYYY-MM")){
                  $(".rbc-date-cell:not('.rbc-off-range')").eq(moment(date).format("D") - 1).html((index, element) => {
                    let str = element
                    let str1 = str.replace('<a href="#">', '<div>')
                    let str2 = str1.replace('</div></a>', '</div></div><br/><div class="reservation-state ' + stateClass + '">' + state + '<br/><div class="reservation-state-text">' + stateText + '</div></div>')
                    return str2
                  })
                }

                this.setJapaneseHolidays()

              }, 0)
            }
          }
        />
        <div className={"calendar-error"} style={{display: calendarError}}>
          <img className={"caution"} src="images/caution.png" alt="caution"/>
          空室情報の取得に失敗しました。<br/>
          インターネットに接続していることを確認して、ページをリロードしてください。
        </div>
      </Fragment>
    );
  }
}

export default Calendar ;
