import React, { Component, Fragment } from 'react';
// import { TwitterTimelineEmbed } from 'react-twitter-embed'
import {Helmet} from "react-helmet";
import GridLoader from "react-spinners/GridLoader";
// import { Link } from 'react-router-dom'
// import Plans from './Plans';
import Calendar from './Calendar';
import News from './News';
import $ from 'jquery';

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true
    }
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({
        isLoading: false
      })
    }, 600)


    var nav    = $('#fixedBox'),
        offset = nav.offset();

    $(window).scroll(function () {
      if($(window).scrollTop() > offset.top) {
        nav.addClass('fixed');
      } else {
        nav.removeClass('fixed');
      }
    });

  }

  render() {
    return (
      <Fragment>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Rubik" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css?family=Sawarabi+Mincho" rel="stylesheet" />

          <link rel="stylesheet" href="../css/hp/animate.css" />
          <link rel="stylesheet" href="../css/hp/icomoon.css" />
          <link rel="stylesheet" href="../css/bootstrap.css" />
          <link rel="stylesheet" href="../css/hp/superfish.css" />

          <link rel="stylesheet" href="../css/hp/style.css" />

        </Helmet>

        <div className='loading' style={{display: !this.state.isLoading ? 'none' : ''}}>
          <GridLoader
            color='#ffffff'
            loading={this.state.isLoading}
            cssOverride={false}
            size={10}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>

        <div style={{display: this.state.isLoading ? 'none' : ''}}>

          <div id="fh5co-wrapper">
            <div id="fh5co-page">
              <div id="fh5co-header">
                <div id="top" className="top">
                  <div className="container">
                    <span> <a href="tel://+81559981433"><i>Tel:</i> 055-998-1433</a></span>
                    <span> <a href="mailto:contact@funwarikon.com"><i>Mail:</i> contact@funwarikon.com</a></span>
                  </div>
                </div>
                <header id="fh5co-header-section">
                  <div className="container">
                    <div className="nav-header">
                      <a href="/#" id="fixedBox" className="js-fh5co-nav-toggle fh5co-nav-toggle"><i></i></a>
                      <h1 id="fh5co-logo">
                        <a href="index.html"><img id="logo" alt="ペンション ふんわりこん" src="../images/logo.png"/></a></h1>
                    <nav id="fh5co-menu-wrap" role="navigation">
                      <ul itemType="http://schema.org/SiteNavigationElement" className="sf-menu" id="fh5co-primary-menu">
                        <li itemProp="name"><a href="/#" itemProp="url">Home</a></li>
                        <li itemProp="name"><a href="#navi_meal" itemProp="url">お食事</a></li>
                        <li itemProp="name"><a href="#navi_facility" itemProp="url">お部屋/設備</a></li>
                        <li itemProp="name"><a href="#navi_fee" itemProp="url">料金</a></li>
                        <li itemProp="name"><a href="#navi_calendar" itemProp="url">空室情報</a></li>
                        <li itemProp="name"><a href="#navi_contact" itemProp="url">ご予約/お問合せ</a></li>
                        <li itemProp="name"><a href="#navi_spots" itemProp="url">周辺情報</a></li>
                        <li itemProp="name"><a href="#navi_access" itemProp="url">アクセス</a></li>
                        <li itemProp="name"><a href="#navi_faq" itemProp="url">よくあるお問合せ(FAQ)</a></li>
                      </ul>
                    </nav>
                    </div>
                  </div>
                </header>
              </div>


              <div className="fh5co-hero">
                <div className="fh5co-overlay"></div>
                <div className="fh5co-cover text-center" style={{backgroundImage: "url(../images/top2.JPG)"}}>
                  <div className="desc">
                    <span id="top-text">森の中の静かなペンション<br/><br/>手作りのお料理とおもてなしで<br/><br/>お待ちしております</span>
                  </div>
                </div>
              </div>
              <div id="sub">
                <p style={{fontFamily:'Rounded Mplus 1c', textAlign: "center", color: "rgba(255, 255, 255, 0.7)", padding: 5, margin:0}}>
                  富士サファリパークへ車で3分！　ぐりんぱ、富士山こどもの国、御殿場プレミアムアウトレットへのアクセスにも最適な立地です。
                </p>
              </div>

              <div style={{backgroundColor: "#104e3dd5", textAlign: 'center'}}>
                <a href="/cottage" target="_blank" rel="noopener noreferrer">
                  <img style={{width: '100%', maxWidth: 1200, cursor: 'pointer', margin: '8px 0'}} alt="twitter" src="../images/cottage-banner.png"/>
                </a>
              </div>

              <News/>

              {/* <div className="corona">
                <div className="panel panel-default" style={{marginBottom:0}}>
                  <div className="panel-heading" style={{backgroundColor: "#b6e2f8"}}>
                    <h4 className="panel-title accordion-toggle" style={{margin:'20 0',textAlign: 'center',fontSize: 18, color: '#7a7e7e'}}>
                      <a data-toggle="collapse" href="#collapse00" className="collapsed">
                        新型コロナウイルス感染症に対する取り組みについて ▼
                      </a>
                    </h4>
                  </div>
                  <div id="collapse00" className="panel-collapse collapse">

                    <h2
                      style={{
                        margin: "24px 10px 18px 10px",
                        fontSize: 14,
                        color: 'black',
                        textAlign: 'center'
                      }}
                    >
                      主な取り組み
                    </h2>
                    <div
                    style={{
                      textAlign: 'left',
                      maxWidth: '800px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    >
                      <ul
                        style={{
                          margin: '0 5% 20px 5%',
                          fontSize: 14,
                        }}
                      >
                        <li>全客室内を始めとする施設内の各所に、アルコール消毒液を設置しております。</li>
                        <li>お部屋内のドアノブ・スイッチ類など、またフロントのルームキーやペン・お釣銭用のトレー・お荷物用のカートなど、各種館内設備にてアルコールによる除菌を実施しております。</li>
                        <li>扉や窓のある場所では可能な限りの換気を実施しております。</li>
                        <li>フロントカウンター・レジ、お客様及び従業員との空間で可能な箇所において飛沫防止パーテーションを設置しております。</li>
                        <li>お客様ごとに貸切家族風呂のご利用前後は脱衣所・ドアノブ・スイッチ類など、アルコール消毒を実施しております。</li>
                      </ul>
                    </div>

                    <h2
                      style={{
                        margin: "24px 10px 18px 10px",
                        fontSize: 14,
                        color: 'black',
                        textAlign: 'center'
                      }}
                    >
                      従業員に対する取り組み
                    </h2>
                    <div
                    style={{
                      textAlign: 'left',
                      maxWidth: '800px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    >
                      <ul
                        style={{
                          margin: '0 5% 20px 5%',
                          fontSize: 14,
                        }}
                      >
                        <li>全スタッフがマスクまたはフェイスシールドを着用しております。</li>
                        <li>手洗い・うがい・手指のアルコール除菌を励行しております。</li>
                        <li>出勤時には体温計測を実施しております。また、体調不良の際（37.5℃以上の発熱など）は出勤禁止とし自宅待機を厳命しております。</li>
                        <li>バックヤードスペースにおいても、ソーシャルディスタンス確保や飛沫防止パーテーションの設置、各種施設のアルコール除菌、出来る限りの換気等を徹底しております。</li>
                        <li>不要不急の出張や不特定多数が集まる会合・イベントへの参加を自粛するとともに、各種会議・イベント・研修等についても実施の際はオンラインの活用を推進しております。</li>
                      </ul>
                    </div>

                    <h2
                      style={{
                        margin: "24px 10px 18px 10px",
                        fontSize: 14,
                        color: 'black',
                        textAlign: 'center'
                      }}
                    >
                      お客様へのお願い
                    </h2>
                    <div
                    style={{
                      textAlign: 'left',
                      maxWidth: '800px',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    >
                      <ul
                        style={{
                          margin: '0 5% 20px 5%',
                          fontSize: 14,
                        }}
                      >
                        <li>当施設にお越しいただく際は、マスクの着用をお願い申し上げます。</li>
                        <li>入口における検温及び手指のアルコール除菌のほか、手洗いやうがいの励行についてご協力をいただきますようお願い申し上げます。</li>
                        <li>チェックイン時・ご精算時・その他各種施設のご利用時等におきましても、ソーシャルディスタンスを確保（1ｍ以上）していただきますようお願い申し上げます。</li>
                        <li>37.5℃以上の発熱を始め、頭痛・のどの痛み・せき・くしゃみ・寒気・節々の痛み・リンパ節の腫れ・味覚や嗅覚の障害などの症状がある場合は、ご来館をご遠慮下さいますようお願い申し上げます。</li>
                        <li>万一、お客様がご滞在中にご体調が優れなくなった際は、直ちにお近くの従業員までお申し出下さいますようお願い申し上げます。</li>
                      </ul>
                    </div>

                    <p style={{
                      margin: '0 5% 20px 5%',
                      fontSize: 14,
                      textAlign: 'center'
                    }}>
                      尚、今後の行政発表等に伴い上記内容に変更があった際は都度お知らせしてまいります。<br/>
                      何卒、皆様のご理解・ご協力を賜りますよう、よろしくお願い申し上げます。
                    </p>

                  </div>
                </div>
              </div> */}

              <hr style={{margin: 0, borderTop: '2px solid #cbcbcb'}} />
              <a href="https://furusatos.com/susono/shops/69" target="_blank" rel="noopener noreferrer">
                <div style={{backgroundColor: "#b3dfdf", textAlign: 'center'}}>
                  <img style={{width: '90%', maxWidth: 600, cursor: 'pointer'}} alt="twitter" src="../images/furusato.png"/>
                </div>
              </a>

              <hr style={{margin: 0, borderTop: '2px solid #cbcbcb'}} />

              {/* <a href="https://www.shizuokagenkitabi.jp" target="_blank" rel="noopener noreferrer">
                <div style={{backgroundColor: "#0098e0", textAlign: 'center'}}>
                  <img style={{width: '90%', maxWidth: 600, cursor: 'pointer'}} alt="twitter" src="../images/fuji_ coupon.png"/>
                </div>
              </a>

              <hr style={{margin: 0, borderTop: '2px solid #cbcbcb'}} /> */}


              {/* <div className="twitter">
                <div className="panel panel-default" style={{marginBottom:0}}>
                  <div className="panel-heading" style={{backgroundColor: "#eaeaea"}}>
                    <h4 className="panel-title accordion-toggle">
                      <a data-toggle="collapse" href="#collapse0" className="collapsed">
                        <img style={{height: 30, paddingBottom: 5, paddingRight:5}} alt="twitter" src="../images/twitter_icon.png"/>
                        Twitterでお得な情報や最新情報をチェック!
                      </a>
                    </h4>
                  </div>
                  <div id="collapse0" className="panel-collapse collapse">
                    <div className="panel-body" style={{textAlign: "center", padding: 30, backgroundImage:"url(../images/leaves.JPG)", backgroundRepeat :"repeat"}}>
                      <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="funwarikon1985"
                        options={{height: 700, width:450}}
                        transparent
                        noheader
                        nofooter
                        noborders
                      />
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <Plans/> */}

              <div style={{paddingTop:50, borderTop: "solid 1px #eaeaea"}}>
                <div id="meal" className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2 className="heading-section" style={{fontSize: 24, paddingTop: 10}}><span id="navi_meal">お食事</span></h2>
                      <p>
                        イタリアン・フレンチなどにこだわらず、洋食素材を和風の味付けにしたり、和食素材を洋風にアレンジしたりと「ふんわりこん流洋食」のコース料理です。<br/><br/>
                        コトコト煮込んだスープやソース類、デザートなど、どれも丁寧に手をかけてお作りした料理を1品ずつお出ししています。
                      </p>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4 col-sm-4">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/dinner_fish.JPG)", backgroundPosition: "bottom"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>ある日のお魚料理</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/dinner_full.JPG)", backgroundPosition: "top"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>ある日のご夕食の一例</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/dinner_main.JPG)", backgroundPosition: "top"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>ある日のお肉料理</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/dinner_dessert.JPG)"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>ある日のデザート</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2 className="heading-section" style={{fontSize: 24, paddingTop: 10}}><span id="navi_meal">自家焙煎コーヒー</span></h2>
                      <p>
                        ふんわりこんのコーヒーは、すべてオーナーが少量ずつ丁寧に焙煎しています。<br/>
                        焙煎したての新鮮なコーヒーを、お楽しみいただけます。
                      </p>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4 col-sm-4">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/coffee1.png)", backgroundPosition: "bottom"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>コーヒー焙煎機</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/coffee2.png)", backgroundPosition: "top"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>自家焙煎コーヒー</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2 className="heading-section" style={{fontSize: 24, paddingTop: 10}}><span id="navi_meal">厳選ワイン</span></h2>
                      <p>
                        ソムリエ資格を持つ、ワイン大好きオーナーが厳選した30種類以上のワインをリーズナブルな価格でご用意しております。
                      </p>
                    </div>
                    <div className="col-md-9">

                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/wine.JPG)", backgroundPosition: "top"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>オーナー厳選ワイン</h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-4 col-sm-4">
                            <div className="fh5co-grid" style={{backgroundImage: "url(../images/house_wine.JPG)", backgroundPosition: "bottom"}}>
                              <div className="image-popup text-center">
                                <div className="prod-title">
                                  <h3>ハウスワイン</h3>
                                </div>
                              </div>
                            </div>
                          </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{paddingTop:30}}>
                <div id="facility" className="container">
                  <div className="row">
                    <div className="col-md-3">
                      <h2 className="heading-section" style={{fontSize: 24, paddingTop: 10}}><span id="navi_facility">お部屋・館内設備</span></h2>
                      <p>
                        {/* ツイン（2ベッド）・トリプル（3ベッド）カルテット（4ベッド）のお部屋タイプがございます。<br/><br/>
                        各お部屋には、シャワートイレと、バスルームまたはシャワールーム(※)を備えている他、家族でご利用いただける檜壁の岩風呂がございます。<br/><br/>
                        ※カルテットタイプのうち1室のリーズナブルなタイプのお部屋を除く */}
                        ツイン（2ベッド）・トリプル（3ベッド）カルテット（4ベッド）のお部屋タイプがございます。<br/><br/>
                        各お部屋には、シャワートイレと、バスルームまたはシャワールームを備えている他、家族でご利用いただける檜壁の岩風呂がございます。
                      </p>
                    </div>
                    <div className="col-md-9">
                      <div className="row">
                        <div className="col-md-4 col-sm-4">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/fire_place2.JPG)", backgroundPosition: "bottom"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>寒い日は薪ストーブの前でゆったり</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/room.JPG)", backgroundPosition: "top"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>トリプルルームの一例</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/bath.JPG)", backgroundPosition: "center"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>森を望む檜壁の岩風呂</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/codama.JPG)", backgroundPosition: "right"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>コテージ こだま</h3>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-4">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/front.JPG)", backgroundPosition: "bottom"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>正面入り口</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/steps.JPG)", backgroundPosition: "top"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>お庭から玄関へ</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8 col-sm-8">
                          <div className="fh5co-grid" style={{backgroundImage: "url(../images/back.JPG)", backgroundPosition: "center"}}>
                            <div className="image-popup text-center">
                              <div className="prod-title">
                                <h3>外庭通路</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              <div className="coffee-banner">
                <a href="https://funwarikon.stores.jp" target="_blank" rel="noopener noreferrer">
                  <img style={{height: 200, paddingBottom: 5, paddingRight:5}} alt="coffee" src="../images/coffee_banner.png"/>
                </a>
                <p>
                  ふんわりこんで、お食事の際に提供させていただいてる<br/>
                  <strong style={{weight: '500', fontSize: "140%"}}>
                    こだわりのコーヒー豆を通信販売でご購入いただけるようになりました。
                  </strong><br/><br/>
                  ふんわりこんのコーヒーは、オーナーが丁寧に少量ずつ鮮度にこだわり自家焙煎しており、<br/>
                  宿泊されたお客様からたくさんのご要望をいただき、販売を開始することとなりました。<br/><br/>
                  ご自宅でも焙煎したてのおいしいコーヒーをお楽しみください。
                </p>
              </div>



              <div style={{background: "rgba(169, 68, 66, 0.8)", padding: 30}}>
                <div id="fee" className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h2><span style={{background: "none", color:"#ffffffd6"}} id="navi_fee">料金のご案内</span></h2>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>お部屋タイプ</th>
                            <th id="price">1名様(2食付)料金</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><div>ツインタイプ(2名様ご利用)</div>シャワールーム・シャワートイレ備付</td>
                            <td rowSpan="3" style={{textAlign: 'left', paddingLeft:40, verticalAlign: 'middle'}}>
                              <div>
                                大人<br/>¥12,800 〜<br/><br/>
                                小学生<br/>¥10,800 〜<br/><br/>
                                幼児<br/>¥9,000 〜<br/><br/>
                                幼児(添寝)<br/>¥6,000 〜<br/><br/>
                                乳児(1歳以下ので添い寝・食事なし)<br/>¥2,500 〜<br/>
                                <i style={{fontSize: '80%'}}>※2歳以上のお子様は必ずお食事をつけさせていただいております。</i>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><div>トリプルタイプ(3名様ご利用)</div>シャワールーム・シャワートイレ備付</td>
                          </tr>
                          <tr>
                            <td><div>カルテットタイプ(4名様ご利用)</div>バス・シャワートイレ備付</td>
                          </tr>
                          {/* <tr>
                            <td><div>リーズナブルカルテットタイプ(4名様ご利用)</div>バス・トイレは、館内の共用トイレ、<br/>檜壁の岩風呂をご利用ください</td>
                            <td　style={{textAlign: 'left', paddingLeft:40, verticalAlign: 'middle'}}>
                              <div>
                                大人<br/>¥11,000 〜<br/><br/>
                                小学生<br/>¥8,000 〜<br/><br/>
                                幼児<br/>¥6,200 〜<br/><br/>
                                幼児(添寝)<br/>¥3,200 〜<br/><br/>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                      <p style={{paddingTop:30, color:"#ffffffb3"}}>
                        上記のプランのほか、<br/>
                        夕食なし、朝食なしのプランや、<br/>
                        幼児の方の添い寝でご利用いただくプランなど、<br/>
                        ご希望に合わせたプランのご用意もございます。<br/>
                        お気軽にお問い合わせください。
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="calendar">
                <h3 style={{color: "#848484", textAlign: "center", marginBottom:0}}><span id="navi_calendar">空室情報</span></h3>
                <Calendar/>
              </div>

              <div style={{background: "rgb(122, 126, 126)", padding: "30px 0"}}>
                <div id="contact" className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h2 style={{fontSize: "2.5em", color: "#ffffffb3", fontWeight: "bold"}}><span style={{background: "none"}} id="navi_contact">ご予約・お問合せ</span></h2>
                      <a href="https://www.hpdsp.net/funwarikon/hw/hwp3100/hww3101.do?yadNo=329166" target="_blank" rel="noopener noreferrer">
                        <div className="reservation-button">WEB予約</div>
                      </a>
                      <p className="web-reservation-statement">
                        乳児のお子様がいらっしゃる方<br/>
                        プランや客室について相談しながら予約を取りたい方<br/>
                        8名様以上の団体・グループでご利用の方<br/>
                        貸切をご希望の方<br/>
                        その他ご質問がある方は、<br/>
                        お気軽にお電話かLINEでお問い合わせください。<br/>
                        ご相談内容に応じたプランをご提案させていただきます。
                      </p>

                      <span> <a id ="tel" href="tel://+81559981433"><i>Tel:</i> 055-998-1433</a></span>
                      <p style={{color: "#ffffffb3"}}>お客様ご対応中などでお電話をお取り出来ない場合もございます。<br/>恐れ入りますが、少しお時間をおいておかけ直しください。</p>
                      <div style={{margin: 20}}>
                        <a href="https://lin.ee/SZYBL0q"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png" alt="友だち追加" height="42" border="0"/></a>
                        <p style={{marginTop: 10, color: "#ffffffb3"}}>LINE公式アカウントからご予約、お問合せ、プランの相談をしていただけます。できるだけ早く返信できるようにいたしますが、お急ぎの場合はお電話ください。</p>
                      </div>
                      <img style={{width:"90%", paddingTop:50}} alt="正面玄関" src="../images/front.JPG"/>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{background: "rgba(169, 68, 66, 0.8)", paddingTop:30}}>
                <div id="spots" className="container">
                  <div className="row">
                    <div className="col-lg-3 col-sm-12">
                      <h2 className="heading-section" style={{fontSize: 24, paddingTop: 10}}><span style={{background: "none", color: "#ffffff"}} id="navi_spots">周辺施設のご案内</span></h2>
                      <p style={{color: "#ffffffb3"}}>当館周辺のおすすめの施設のご紹介です。<br/>魅力あふれる多彩なスポットをどうぞお楽しみください。 </p>
                    </div>
                    <div className="col-lg-9 col-sm-12">
                      <div className="row">

                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="https://www.sauna-club.jp/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>徒歩で4分</span>
                                <h3>Thermal Climb Studio FUJI</h3>
                                <img style={{maxWidth: 220, paddingBottom: 30}} src="../images/sauna.JPG" alt="sauna-club-logo"/>
                                <p>見たことのない、感じたことのないサウナが体験できるテーマパーク。お風呂のソムリエとしてTV、ラジオ、雑誌などで活躍する松永武氏がプロデュース。</p>
                              </div>
                            </a>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.fujisafari.co.jp/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で3分</span>
                                <h3>富士<br/>サファリパーク</h3>
                                <p>マイカーや園内バスに乗って、日本最大級のサファリゾーンで間近の動物たちを観察できる大人気の施設です。世代を問わず楽しめる間違いなしのおすすめスポットです。</p>
                              </div>
                            </a>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.grinpa.com/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で12分</span>
                                <h3>ぐりんぱ<br/>-GrinPa-</h3>
                                <p>富士山2合目南麓に位置する遊園地。イベントも充実しています。シルバニアビレッジはファン必見。雨天でも遊べる施設もあります。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.kodomo.or.jp/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で3分</span>
                                <h3>富士山<br/>こどもの国</h3>
                                <p>94.5ヘクタールある広大な公園で、目一杯自然を感じて一日中遊べる施設。カヌーに乗ったり、動物とふれあえたり、各種体験プログラムも充実しています。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.premiumoutlets.co.jp/gotemba/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で30分</span>
                                <h3>御殿場<br/>プレミアム<br/>アウトレット</h3>
                                <p>日本最大級のアウトレットモール。ハイブランドからスポーツブランド、インテリアまで、数多くのアイテムをアウトレットプライスでショッピングを漫喫できます。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="https://www.fujiq.jp/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で55分</span>
                                <h3>富士急ハイランド</h3>
                                <p>「FUJIYAMA／ええじゃないか 」を始めとする4大ジェットコースターや、家族で楽しめる「トーマスランド」等、言わずと知れた大人気遊園地です。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.naturalaction.co.jp/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で40分</span>
                                <h3>NATURAL ACTION OUTDOOR TOURS </h3>
                                <p>日本三大急流にも数えられる富士川でのラフティング(川下り)ツアーや、朝霧マウンテンバイクツアーなど、富士山の麓の自然を感じられるツアーが満載。お子様と一緒でも大丈夫です。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.yeti-resort.com/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で15分</span>
                                <h3>スノータウン<br/>イエティ</h3>
                                <p>富士山でスノースポーツを楽しめます。日本一早く10月中旬から営業を開始します。コースは小規模ですが、日本一の富士山での滑走は一度体験する価値ありです。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://www.tokinosumika.com/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で25分</span>
                                <h3>御殿場高原<br/>時之栖</h3>
                                <p>温泉や地ビールを漫喫できる施設です。広大な敷地の中、大人から子供まで楽しめます。秋〜春に開催しているイルミネーションは大人気です。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="http://jukuu.jp/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>車で20分</span>
                                <h3>富士山<br/>樹空の森</h3>
                                <p>富士山麓模型を立体スクリーンとしてCG映像で自然の表情を映し出す富士山天空シアターが人気。アスレチックや、富士山を望む温泉を漫喫できます。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="fh5co-blog">
                            <a href="https://www.susonost.com/" target="_blank" rel="noopener noreferrer">
                              <div className="prod-title">
                                <span>お知らせ</span>
                                <h3>裾野市<br/>スポーツ<br/>ツーリズム</h3>
                                <p>準高地トレーニングに適した標高と首都圏からのアクセスの良さを併せ持つ裾野市では、スポーツ合宿の手厚いサポートを市で行っています。お気軽にご相談ください。</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{background: "rgb(255, 255, 255)", padding: "50px 0"}}>
                <div id="access" className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h2><span id="navi_access" style={{background: "none"}}>アクセス</span></h2>
                      <span>〒410-1231<br/>静岡県裾野市須山十里木高原 10-157</span>
                      <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3257.9327467574713!2d138.78866598711258!3d35.25792888209556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60197ed0a4c36bc1%3A0x2f842500b17c866b!2z44Oa44Oz44K344On44OzIOOBteOCk-OCj-OCiuOBk-OCkw!5e0!3m2!1sja!2sjp!4v1509286657347" width="600" height="450" frameBorder="0" style={{border:0, marginTop:50, width: "90%"}} allowFullScreen></iframe>
                      <h4><img alt="car" src="../images/car.png"/>自動車</h4>
                      <h5>東京から (約1.5時間)</h5>
                      <span>東京 → 東名高速 → 裾野IC → 国道469号 → 富士サファリ → 西へ３分 → ふんわりこん</span>
                      <h5>名古屋から (約3時間)</h5>
                      <span >名古屋 → 東名高速 → 富士IC → こどもの国 → 東へ３分 → ふんわりこん</span>
                      <h4><img alt="train" src="../images/train.png"/>電車 / <img alt="bus" src="../images/bus.png"/>バス</h4>
                      <h5>JR御殿場駅下車</h5>
                      <span>JR御殿場駅より路線バス「十里木行き」で40分、「八幡神社公園前」下車で目の前</span>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{background: "rgb(122, 126, 126)", paddingTop: 30}}>
                <div id="faq" className="container">
                  <div className="row">
                    <div className="col-md-8 col-md-offset-2 text-center">
                      <h2 id="contact-text" style={{color:"white"}}><span id="navi_faq" style={{background: "none"}}>よくあるお問合せ(FAQ)</span></h2>
                      <section className="faq">
                        <div className="panel-group" id="accordion">
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse1" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">チェックインは何時から何時までですか?</p>
                              </h4>
                            </div>
                            <div id="collapse1" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>チェックイン時間は以下の通りです。<br/>
                                  <br/>
                                  夕食付きの方: 15:00〜17:30<br/>
                                  夕食なしの方: 15:00〜22:00<br/>
                                  <br/>
                                  ※チェックイン時間を過ぎそうな場合は、必ず事前のご連絡をお願いいたします。
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse2" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">チェックアウト時間は何時ですか？</p>
                              </h4>
                            </div>
                            <div id="collapse2" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>チェックアウト時間は10:00でございます。</p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse3" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">夕食の時間は何時からですか？</p>
                              </h4>
                            </div>
                            <div id="collapse3" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>ご夕食は18:00にご用意させていただきます。<br/><br/>
                                ※ご夕食のお時間に間に合わない場合は、16:30までにご連絡ください。
                                19:30まではお食事をお取り置きさせていただきますが、その際、一部内容が異なる場合がございますのでご了承ください。
                                あらかじめご夕食の時間に間に合わないと思われる場合は、前日までに夕食無しのブランに変更いただきますようお願いいたします。</p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse4" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">朝食の時間は何時からですか？</p>
                              </h4>
                            </div>
                            <div id="collapse4" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>朝食は8:00にご用意させていただきます。</p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse5" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">客室にはどんなアメニティがありますか？</p>
                              </h4>
                            </div>
                            <div id="collapse5" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>
                                  各お部屋に、以下のアメニティのご用意がございます。<br/><br/>
                                </p>
                                <p style={{textAlign:"left", marginLeft:"5%"}}>
                                  ・バスタオル<br/>
                                  ・フェイスタオル<br/>
                                  ・歯ブラシ(お子様用もあります)<br/>
                                  ・シャンプー<br/>
                                  ・コンディショナー<br/>
                                  ・ボディソープ<br/>
                                  ・ナイトガウン<br/>
                                  　<span>
                                    {/* （バス又はシャワールーム付きのお部屋のみ•大人用のみ）<br/> */}
                                      (大人用のみ）<br/>
                                  　　※ナイトガウンはお部屋着ですので食堂、ラウンジでの着用はできません
                                    </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse6" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">貸切でのご利用はできますか？</p>
                              </h4>
                            </div>
                            <div id="collapse6" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>
                                  はい、可能でございます。<br/>
                                  お日にちや人数によってお見積もりいたしますので、<br/>
                                  お気軽にお問い合わせください。
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse7" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">食事のアレルギーへの対応は可能ですか？</p>
                              </h4>
                            </div>
                            <div id="collapse7" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>大変申し訳ございませんが、アレルギー除去食への対応はできません。<br/><br/>
                                万一、食材の一部がアレルギーの対象となってしまった場合などを考慮し、お客様の安全を第一とさせていただきたい為、ご了承いただけますようお願いいたします。</p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse8" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">各部屋にお風呂はありますか？</p>
                              </h4>
                            </div>
                            <div id="collapse8" className="panel-collapse collapse">
                              <div className="panel-body">
                                {/* <p>リーズナブルカルテットタイプのお部屋を除き、各部屋にユニットバス、またはシャワールームがございます。<br/><br/> */}
                                <p>
                                  各部屋にユニットバス、またはシャワールームがございます。<br/><br/>
                                  また、各グループ(1室のご利用)あたり、貸し切りの岩風呂を40分間ご利用いただけます。<br/>
                                  岩風呂のご利用時間は、チェックインの際に先着での予約制となっております。
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse9" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">キャンセル料について教えてください。</p>
                              </h4>
                            </div>
                            <div id="collapse9" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>
                                  キャンセル料は以下の通りとなっております。<br/><br/>
                                </p>
                                <p style={{textAlign:"left", marginLeft:"5%"}}>
                                  9日より前のキャンセル            　: 無料<br/>
                                  3日前〜8日前のキャンセル           : 宿泊料の30%<br/>
                                  前日/前々日のキャンセル　          : 宿泊料の60%<br/>
                                  当日のキャンセル/連絡なしのキャンセル: 宿泊料の100%
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse10" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">飲食物等の持ち込みは可能ですか？</p>
                              </h4>
                            </div>
                            <div id="collapse10" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>飲食物のお持込は離乳食、アレルギー除去食に限らせていただいております。<br/><br/>
                                夕食後も22:00まではダイニングルームでフード、ドリンクをご注文いただけますのでどうぞご利用ください。<br/><br/>
                                その他、特にお持込を希望される場合は事前にご相談ください。
                              </p>
                              </div>
                            </div>
                          </div>
                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse11" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">ペットを連れていくことは可能ですか？</p>
                              </h4>
                            </div>
                            <div id="collapse11" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>恐れ入りますが、盲導犬・聴導犬・介助犬を除き、館内へのペットの持ち込みはお断りさせていただいております。</p>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse12" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">どんな支払方法が使えますか？</p>
                              </h4>
                            </div>
                            <div id="collapse12" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>現金、クレジットカード、電子マネー(QuickPay、iD、各種交通系電子マネー)、PayPayがご利用いただけます。</p>
                              </div>
                            </div>
                          </div>

                          <div className="panel panel-default">
                            <div data-toggle="collapse" data-target="#collapse13" className="panel-heading">
                              <h4 className="panel-title accordion-toggle">
                                <p className="collapsed">花火はできますか？</p>
                              </h4>
                            </div>
                            <div id="collapse13" className="panel-collapse collapse">
                              <div className="panel-body">
                                <p>敷地内での花火はご遠慮いただいております。</p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>

              <footer>
                <div id="footer">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6">
                        <p>Copyright 2017 ペンション ふんわりこん. All Rights Reserved.</p>
                      </div>
                      <div className="col-md-6">
                        <ul className="footer-menu">
                          <li><a href="/#">ページトップへ戻る</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>

            </div>
          </div>

        </div>
      </Fragment>
    );
  }
}

export default Main ;
