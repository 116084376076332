import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Main from './Main'
import NewsArticle from './NewsArticle'
import Cottage from './Cottage'

class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={Main} />
          <Route exact path='/news/:newsId' component={NewsArticle} />
          <Route exact path='/cottage' component={Cottage} />
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App ;
