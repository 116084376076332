import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import { Link } from 'react-router-dom'
import moment from 'moment';
import './css/whats-new.css';


class News extends Component {

  constructor(props) {
    super(props);
    this.state = {
      news: []
    }
  }

  async componentWillMount(){
    try{
      let apiName2 = 'MyAPIGatewayAPI2'; // replace this with your api name.
      let path2 = '/news'; //replace this with the path you have configured on your API

      const response = await API.get(apiName2, path2)
      console.log(response);

      let news = response.news.sort((a, b) => {
        return moment(a.date).isBefore(moment(b.date)) ? 1 : -1
      })

      this.setState({
        news: news
      })

    }catch(error){
      console.log(error)
    }
  }


  render() {
    return (
      <Fragment>
        <div style={{backgroundImage: 'url(../images/leaves.JPG)'}}>
          <div
            style={{
              padding: '32px 0 8px 0',
              textAlign: 'center',
              display: this.state.news.length > 0 ? '' : 'none'
            }}
           >

             <div
               style={{
                 backgroundColor: '#ffffff70'
               }}
              >

              <h1
                style={{
                  margin: "0 0 16px 0",
                  fontSize: 20,
                  fontWeight: '500',
                  color: 'black',
                  borderBottom: 'solid',
                  borderColor:'#878787',
                  borderWidth: 2,
                  display: 'inline-block'
                }}
              >
                新着情報
              </h1>
              <div className="whatsnew">

              {this.state.news.map(newsItem => {
                return (
                  <Link key={newsItem.news_id} to={"/news/" + newsItem.news_id} target="_blank">
                    <div className="item">
                      <div className="days">
                        {moment(newsItem.date).format('YYYY.MM.DD')}
                      </div>
                      <div className="text">
                        {newsItem.title}
                      </div>
                    </div>
                  </Link>
                )
              })}

              </div>
            </div>
          </div>
          <a href="https://www.instagram.com/pension_funwarikon/" target="_blank" rel="noopener noreferrer" style={{padding: '16px 0', display:"flex", justifyContent: 'center', alignItems: 'center'}}>
            <img style={{width: '100%', maxWidth: 120, cursor: 'pointer'}} alt="instagram" src="../images/instagram-icon.png"/>
            <p style={{margin: '0 0 0 5px', fontSize:'1em', textDecoration: 'none'}}>こちらから</p>
          </a>
        </div>
      </Fragment>
    );
  }
}

export default News ;
