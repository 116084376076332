import React, { Component, Fragment } from 'react';
import { API } from 'aws-amplify';
import moment from 'moment';
import {Helmet} from "react-helmet";

const lbToBr = (txt) => {
  return (
    txt.split(/(\n)/g).map((t,i) => (t === '\n')?<br key={i} />:t)
  )
}


class NewsArticle extends Component {

  constructor(props) {
    super(props);
    this.state = {
      news: {}
    };
  }


  async componentWillMount(){

    let newsId = this.props.location.pathname.split('/')[2]

    try{
      let apiName2 = 'MyAPIGatewayAPI2'; // replace this with your api name.
      let path2 = '/news/' + newsId; //replace this with the path you have configured on your API

      const response = await API.get(apiName2, path2)
      console.log(response);

      this.setState({
        news: response.news
      })

    }catch(error){
      console.log(error)
    }
  }


  render() {
    if(typeof this.state.news.news_id !== 'undefined'){
      return (
        <Fragment>

          <Helmet>

            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
            <link href="https://fonts.googleapis.com/css2?family=Rubik" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c" rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Sawarabi+Mincho" rel="stylesheet" />

            <link rel="stylesheet" href="../css/hp/animate.css" />
            <link rel="stylesheet" href="../css/hp/icomoon.css" />
            <link rel="stylesheet" href="../css/bootstrap.css" />
            <link rel="stylesheet" href="../css/hp/superfish.css" />

            <link rel="stylesheet" href="../css/hp/style.css" />

          </Helmet>

          <div id="fh5co-wrapper" style={{backgroundImage: 'url(../images/leaves.JPG)', height: '100vh'}}>
            <div id="fh5co-page">

              <div style={{padding: "32px 18px"}}>
                <div className="container">
                  <div className="row" style={{backgroundColor:'#ffffff70'}}>
                    <div className="col-md-8 col-md-offset-2">
                      <div style={{textAlign: 'center', marginBottom:8, fontWeight: 'bold', color: '#a72626'}}>
                        {moment(this.state.news.date).format('YYYY.MM.DD')}
                      </div>
                      <h1 style={{fontSize: "1.8em", color: "black", fontWeight: "bold", textAlign: 'center'}}>
                        <span style={{background: "none"}} id="navi_contact">
                          {this.state.news.title}
                        </span>
                      </h1>

                      <div style={{textAlign: 'center'}}>
                        <img style={{width:"95%", paddingBottom: 32, display: this.state.news.image_path ? '' : 'none'}} src={this.state.news.image_path} alt="main_image"/>
                      </div>

                      <p style={{
                        fontSize: '1.2em',
                        color: '#2a2a2a',
                      }}>
                        {typeof this.state.news.text == 'undefined' ? '' : lbToBr(this.state.news.text)}
                      </p>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </Fragment>
      );
    }

    return <Fragment></Fragment>

  }
}

export default NewsArticle ;
